@import "common";

$primary_text_color: #333;
$secondary_text_color: #444;
$navbar_width: 240px;

@keyframes flyin {
  from {
    left: -100px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #999;
  font-style: italic;
}

.text-blue {
  color: $newBlue;
}

.link-blue {
  color: $newBlue;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:active {
    color: darken($newBlue, 10%);
  }

  &.disabled {
    color: #777;
    pointer-events: none;
    cursor: default;
  }
}

.ui-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1299; //z-index is 1299 to make sure React modals with z-index of 1300 show up.
  pointer-events: none;
  background: white;
  animation: flyin 500ms;
  display: flex;

  &.vertical {
    display: flex;
    flex-direction: column;
    .ui-navigation {
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      flex-direction: row;
      //background: #383A3F;

      .ui-navigation-back-button {
        width: 70px;
        height: 100%;
      }

      .ui-navigation-items {
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding: 0px 0px;
      }

      .ui-navigation-item {
        //color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 100%;
        margin-top: 0px;
        margin-right: 0px;
        padding: 0px 30px;
        //&.selected {
        //  background: #111;
        //}
      }
    }

    .ui-pane {
      height: calc(100% - 50px);
    }
  }

}

.ui-navigation {
  //width: 70px;
  width: $navbar_width;
  //opacity: 0;
  height: 100vh;
  //background: #383A3F;
  background: #333;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: auto;


  &.skinny {
    width: 120px;

    .ui-navigation-item {
      .nav-label {
        flex-direction: column;
      }
    }
  }

  .workspace {
    background: rgba(0,0,0,.25);
    color: white;
    padding: 10px 0px;
    margin-left: -15px;
    width: calc(100% + 29px);
    padding-left: 23px;
    margin-bottom: 10px;
    .title {
      text-transform: uppercase;
      opacity: 0.5;
      margin-bottom: 6px;
      font-size: 11px;
    }
    .label {
      font-size: 14px;
    }
  }

  .ui-navigation-items {
    padding: 14px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &.full-height {
      height: calc(100% - 50px);
    }

    .category {
      font-size: 10px;
      text-transform: uppercase;
      color: #333;
    }

    & > label {
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .ui-navigation-item {
    position: relative;
    color: rgba(255, 255, 255, 0.9);
    //color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 12px;
    white-space: nowrap;
    width: calc(100% + 15px);


    & + .ui-navigation-item {
      margin-top: 6px;
    }
    .nav-label {
      display: flex;
      align-items: center;
      white-space: break-spaces;
    }

    .icon-container {
      position: relative;
      display: flex;
      margin-right: 10px;
    }

    .icon-label {
      position: absolute;
      top: -10px;
      left: 19px;
    }

    i {
      margin-right: 10px;
      opacity: 0.75;
      color: $newBlue;
    }

    img {
      margin-right: 10px;
      opacity: 0.75;
      height: 20px;
    }

    &:hover {
      color: white;
      background: rgba(0,0,0,0.25)
    }

    &.selected {
      color: #333;
      background: rgb(238, 238, 238);
    }

    &.vertical {
      width: calc(100% + 15px);
      .nav-label {
        width: calc(100% - 15px);
        flex-direction: column;
        justify-content: center;
      }
      .icon-container {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }

    .pro-badge {
      margin-top: 1px;
    }
  }

  .ui-sub-navigation-item {
    color: rgba(255, 255, 255, 0.85);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 30px;
    text-transform: none;
  }

  .ui-navigation-item-header {
    color: rgba(255, 255, 255, 0.45);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    display: grid;
    margin-bottom: 10px;

    &.full-height {
      flex: 1;
      align-content: flex-start;
    }

    .title {
      margin-bottom: 10px;
      padding: 10px;
      font-size: 12px;
    }
  }
}

.ui-pane {
  //color: white;
  //background: $body_background;
  color: #333;
  position: relative;
  background: $lightGray;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  font-size: 14px;

  &.with-sidebar {
    flex-direction: row;
    height: calc(100% - 48px);

    .ui-inner-pane {
      display: flex;
      flex-direction: column;
    }
  }

  .ui-pane-header {
    width: 100%;
    //max-height: 55px;
    //min-height: 55px;
    background: $lightGray;
    color: $darkGray;
    display: flex;
    align-items: center;
    pointer-events: auto;
    padding: 0px 20px;
    height: 50px;
    flex-shrink: 0;

    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;

    //display: none;

    .pro-badge {
      position: initial;
      margin: 1px 0px 0px 10px;
    }

    .searchbar {
      width: 100%;
      pointer-events: auto;
      border-radius: 0;
      border: 0;
      display: flex;
      align-items: center;
      z-index: 100;

      .micon {
        font-size: 30px;
        color: #11a9e2;
        cursor: default;
        margin-right: 6px;
      }

      .clear_search {
        display: none;
        color: #ccc;
        font-size: 24px;
      }

      input[type="text"] {
        flex-grow: 2;
        font-size: 20px;
        font-weight: 600;
        border: none;
        padding: 0px;
        background: none;
        outline: none;

        &::placeholder {
          color: #666;
        }

      }
    }

  }

  .ui-inner-pane {
    padding: 4px 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  .ui-pane-sidebar {
    position: relative;
    //background: $lightGray;
    .panel {
      background: white;
      width: 260px;
      height: 100%;
      flex-shrink: 0;
      padding: 10px 10px 10px 10px;
    }
  }

  .section-title {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px 10px 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .section-description {
    color: #555;
    margin-bottom: 20px;
    margin-top: -10px;
  }



  section {
    background: white;
    padding: 10px 20px 15px;
    margin: 0px 0px 20px;
    position: relative;

    &.upgrade-section {
      border: 2px solid #11a9e2;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }



}

section.field {
  //background: white;
  padding: 0px;
  margin: 0px 0px 40px;
  position: relative;
  &:last-of-type {
    margin-bottom: 0px;
  }
  .field-label {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.beautiful-ui {
  font-family: "Source Sans Pro";

  a {
    color: $selectionColor;

    &:hover {
      cursor: pointer;
    }
  }

  .micon {
    font-family: "Material Icons";
    font-size: 20px;
    color: inherit;

    &.small {
      font-size: 16px;
    }

    &.large {
      font-size: 30px;
    }

    &.light {
      color: white;
    }

    &.dark {
      color: #333;
    }
  }

  h1 {
    color: $primary_text_color;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 10px 0px;
  }

  h2 {
    color: $primary_text_color;
    font-size: 30px;
    font-weight: 600;
    //text-transform: uppercase;
    //letter-spacing: 0.05em;
    //margin: 10px 0px;
    //background: rgba(0, 0, 0, 0.2);
    //padding: 15px 20px;
    //position: relative;
    //left: -20px;
    //width: calc(100% - 20px);
  }

  h3 {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin: 0px;
  }

  .gap {
    width: 0px;
    display: block;
  }

  input {
    font-family: "Source Sans Pro";
    //color: white;
  }

  .textField {
    margin-right: 20px;

    div:after {
      border-bottom-color: white !important;
    }
  }

  //hr {
  //  border-style: none;
  //  height: 1px;
  //  background: rgba(0, 0, 0, 0.15);
  //  margin: 30px 0px;
  //}

  .section {
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .radio-group {
    display: grid;
    grid-row-gap: 20px;
    margin: 20px 0px;

    .radio-button-frame {
      .radio-button {
        display: flex;
        align-items: center;

        .label-frame {
          position: relative;
          top: -1px;

          .label {
            color: $primary_text_color;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }

      .description {
        text-transform: none;
        display: block;
        font-size: 14px;
        color: $secondary_text_color;
        margin-top: -6px;
        margin-left: 50px;
      }
    }

    .radio-button-container {
      margin: 20px 0px 0px 50px;

      &.disabled {
        opacity: 0.33;
        pointer-events: none;
      }
    }
  }


  .checkbox {
    input {
      margin-right: 10px;
    }
  }

  .control-label {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .recover-deleted-slides-header{
    justify-content: space-between;
  }

  .recover-deleted-slides-header-warning-message{
    display: flex;
    align-items: center;
  }

  .slidegrid {
    display: grid;
    width: 100%;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 200px);

    .slide-thumbnail {
      width: 200px;
    }
  }

  .slide-thumbnail {
    width: 200px;
    height: 112.5px;
    background: #222;

    img {
      width: 100%;
    }
  }

  .error-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    margin: 20px 0px;
    color: white;

    i {
      font-size: 25px;
      margin-right: 10px;
    }

    .error-title {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
    }

    .error-message {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 600;
      color: #ccc;
    }
  }

  .block {
    padding: 0px;

    .title {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
    }
  }


}

.beautiful-list {
  position: relative;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .list-header {
    padding: 0px 20px;
    display: grid;
    grid-column-gap: 20px;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    //border-bottom: solid 2px #11a9ef;

    .list-field-header {
      box-sizing: content-box;
      font-size: 12px;
      font-weight: 600;
      color: #444;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .sort-indicator {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }

  .list-item {
    font-size: 16px;
    color: #333;
    display: grid;
    grid-column-gap: 20px;
    align-items: center;
    position: relative;
    padding: 10px 10px 10px 20px;
    min-height: 60px;
    margin-bottom: 10px;
    background: white;
    overflow: hidden;

    img {
      height: 100px;
    }

    .avatar {
      &.list-field {
        min-width: unset;
      }

      & > i {
        font-size: 80px;
      }
    }

    .profile {
      display: flex;
      align-items: center;
    }

    .avatar {
      background: #333;
      width: 30px;
      height: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & > i {
        width: 30px;
        height: 30px;
        font-size: 21px;
        line-height: 30px;
        text-align: center;
        color: #ccc;
      }

      img {
        width: 100%;
        height: 100%;
      }

      & > i.micon.delete {
        position: absolute;
        top: -7px;
        right: -7px;
        font-size: 14px;
        background: #50bbe6;
        border-radius: 100px;
        padding: 2px;
        color: white;
        display: none;
        cursor: pointer;
      }

      &:hover i.micon.delete {
        display: block;
      }
    }

    .name {
      width: 250px;

      .fullname {
        display: block;
        color: #333;
      }

      .email {
        font-size: 12px;
        color: #555;
        display: block;
      }
    }

    &.disabled {
      opacity: 0.5;
    }

    &:not(.no-highlight) {
      .resend-invite-button {
        opacity: 0;
        transition: opacity, color 0.2s ease;
      }

      &:hover {
        background: $lightBlue;

        & > .show-on-hover {
          opacity: 1;
        }
        .resend-invite-button {
          opacity: 1;
        }
      }
    }

    .list-field {
      box-sizing: content-box;
      min-width: 50px;

      &.bold {
        font-weight: 600;
      }

      &.label {
        padding: 4px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
        font-size: 12px;
        min-width: 50px;
        text-align: center;
      }

      &.flex {
        flex-grow: 2;
      }

      &.select-field {
        margin-left: 10px;
      }
    }

    .avatar {
      &.list-field {
        min-width: unset;
      }
    }

    & > .show-on-hover {
      opacity: 0;
      transition: opacity 300ms;
    }

    & > button.list-item-icon-button {
      max-width: 44px;
      .micon {
        font-size: 20px;
      }
    }
  }
}
.list-field.displayName {
  .email {
    font-size: 12px;
    font-weight: 400;
    color: #666;
  }
}

.row-grid {
  display: grid;
}

.col-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.flex-box {
  display: flex;
  align-items: center;
}

.menu-item-badge {
  background: #7fb314;
  color: white;
  font-size: 10px;
  letter-spacing: 0.5px;
  padding: 2px 8px;
  display: inline-block;
  margin-left: 30px;
  font-weight: 600;
  position: absolute;
  top: calc(50% - 11px);
  right: 30px;
}

.image-thumbnail {
  transition: opacity 400ms;
}

.image-error-warning {
  background: orangered;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  padding: 20px;
}

.thumbnail-grid {
  margin-top: 2px;
}

// prevents accidentally allowing the browser to drag the
// image as if it were a file out of the grid area
.thumbnail-grid-item img {
  pointer-events: none;
}

.empty-image-thumbnail-grid-message {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.large-radio {
  margin-top: 10px;
  margin-bottom: 10px;

  .radio-button-label {
    font-size: 18px;
    color: #333;
  }

  .radio-button-description {
    font-size: 14px;
    margin-top: -5px;
    margin-left: 50px;
  }
}

img.dropbox-logo {
  height: 20px;
  margin-right: 5px;
}

.react-popup-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;

  .react-popup-menu {
    transition: opacity 300ms;

    label {
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 10px;
    }
  }
}

.icon-button {
  margin: 0px 10px;
  display: flex;
  align-items: center;

  i {
    font-size: 16px;
    color: $selectionColor;
  }

  &:hover {
    i {
      color: $darkSelectionColor;
    }
  }
}

.sidebar-folder-list {
  margin-top: 10px;

  .folder-item {
    margin-bottom: 20px;

    span {
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, .75);
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 8px 12px;

      &:hover {
        color: white;
        background: rgba(0, 0, 0, .3);
      }

    }

    &.selected {
      span {
        color: white;
        //background: rgba(0, 0, 0, .25);
        background: $newBlue;
      }
    }
  }
}


//.thumbnail-grid {
//  margin-bottom: 20px;
//}

button {
  &.orange {
    color: white;
    background: #cb3d0c;

    &:hover {
      background: #872708;
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &.yellow {
    background: #fa0;

    &:hover {
      background: darken(#ffaa00, 5%);
    }
  }

  &.warning{
    background-color: $warning;
    padding: 6px 30px;
  }


  &.dark {
    color: #f1f1f1;
    background: #333;

    &:hover {
      background: #222;
    }
  }
}


.light-background {
    color: #333;
    .MuiPaper-root {
      color: #333;
    }
}

.MuiFab-root {
  &.floating-fab {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &.expanding-fab,
  &.MuiFab-extended.expanding-fab {
    background: white;
    color: #777;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    cursor: pointer;
    padding: 0 12px;
    max-width: 24px;
    transition: max-width 1.5s;

    & > .MuiFab-label {
      overflow: hidden;
      white-space: nowrap;
      justify-content: start;
    }

    &:hover,
    &:focus {
      max-width: 100%;
    }
  }
}



.MuiList-root {
  .MuiPaper-root {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }

  .MuiList-padding {
    padding: 0px;
  }

  .MuiIcon-root, .MuiSvgIcon-root {
    color: #666666;
    margin-right: 8px;
  }

  hr {
    background: #ddd;
    border: none;
    height: 1px;
    margin: 0;
  }

  .MuiMenuItem-root {
    font-family: "Source Sans Pro";
    padding: 14px 20px 14px 14px;
    font-size: 13px;
    font-weight: 600;
    color: #333;
    .MuiIcon-root {
      margin-right: 6px;
    }
  }
}

.MuiSelect-selectMenu {
  .MuiIcon-root {
    color: #666;
    margin-right: 10px;
    font-size: 1.3rem;
    vertical-align: -6px;
  }
}

.MuiTooltip-tooltip {
  //background: white !important;
  //color: #111 !important;
  //font-size: 14px !important;
  //border: solid 1px $newBlue !important;
  //border-radius: 4px !important;
  //padding: 10px 16px !important;
  //box-shadow: 2px 2px 5px rgba(0,0,0,.2);
  //max-width: 400px !important;
  //p+p {
  //  margin-top: 10px;
  //}
}
.MuiTooltip-arrow {
  //color: #111 !important;
}

.popper-white {
  .MuiTooltip-tooltip {
    background: white !important;
    color: #111 !important;
    padding: 0 !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  }
  .MuiTooltip-arrow {
      color: white !important;
  }
}

.rounded-5 {
  border-radius: 5px;

  .MuiTooltip-tooltip {
    border-radius: 5px !important;
  }
}

.is_mobile {
  .ui-pane {
    .ui-pane-header {
      position: relative;
      background: #111;
      color: white;
      padding-right: 0;

      .ui-inner-pane {
        padding: 10px;
      }

      .exit-button {
        position: absolute;
        right: 0;
        color: white;
      }
    }
  }
}

.dark-mode {
  background-color: #111;
  caret-color: $newBlue;

  input {
    color: #eee;
    outline: none;
  }

  .UploadAvatar-paper-5 {
    background-color: #111;
  }

  .MuiMenu-paper {
    background: #333;
  }

  .MuiPaper-root {
    color: rgba(255, 255, 255, 0.87);
    background-color: #333;
  }

  .MuiButton-root {
    color: rgba(255, 255, 255, 0.87);

    &.MuiButton-textPrimary {
      color: #11a9e2;
    }
  }

  .MuiList-root {
    .MuiMenuItem-root {
      color: #ccc;

      .MuiIcon-root {
        color: #aaa;
      }

      &:hover {
          color: white;
          background-color: $ui_focused_color;

          .MuiIcon-root {
            color: white;
          }
      }

      &.Mui-selected {
        color: $ui_focused_color;
        background-color: #111 !important;
        font-weight: 600;

        .MuiIcon-root {
          color: $ui_focused_color;
        }
      }
    }

    hr {
      background: #555;
    }
  }

  .MuiIconButton-root {
    color: rgba(255, 255, 255, 0.54);
  }

  .ui-pane {
    color: #ddd;
    background: $darkGray;

    input {
      color: #ddd;
    }

    .ui-pane-header {
      background: $darkGray;
      color: $lightGray;

      .searchbar {

        .micon {
          color: #ddd;
        }

        .clear_search {
          color: #444;
        }

        input[type="text"] {
          &::placeholder {
            color: #999;
          }
        }
      }
    }

    .section-title {
      color: #eee;
    }

    .section-description {
      color: #bbb;
    }

    section {
      background: #111;
    }
  }
}

@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}


.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Source Sans Pro", helvetica, arial, sans-serif;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  color: #000;
  display: flex;
  font-size: .8rem;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 10000;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range), .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
}

.react-datepicker__input-container input {
  padding: 8px 10px;
  //width: 62px;
  //width: 100%;
  font-size: 12px;
  font-weight: bold;
  border: solid 1px #ccc;
  border-radius: 0px;
  &:focus {
    border: solid 1px $newBlue;
  }
  outline: none;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}
